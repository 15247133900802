<template>
  <div class="promo">
    <div class="contentWrapper">
      <h1>Promo</h1>
      <div class="copyWrapper">
        <div class="promoOffer" v-if="promoDetails != null">
          <div class="promoName">{{ promoDetails.promoDisplayName }}</div>
        </div>
        <div class="userMessage" v-if="userMessage != ''">{{ userMessage }}</div>
      
      
        <div class="promoDetails" v-if="userMessage ==''">
          This promo contains:

          <div class="cartWrapper">
            <cart-item :itemData="item" unitPriceProperty="unitPrice" v-for="item in promoDetails.promoItems" :key="item.id"></cart-item>
            <div class="priceRows">
              <div class="subtotalRow">
                <div class="spacer"></div>
                <div class="rowLabel">Subtotal:</div>
                <div class="rowPrice">${{ currencyFormat(cartSubtotal) }}</div>
              </div><!--END subtotalRow-->

              <div class="discountRow" v-if="this.promoDetails.promoDiscount != 0">
                <div class="spacer"></div>
                <div class="rowLabel">Discount:</div>
                <div class="rowPrice">
                  -${{ currencyFormat(this.promoDetails.promoDiscount) }}
                </div>
              </div><!--END subtotalRow-->

              <div class="totalRow">
                <div class="spacer"></div>
                <div class="rowLabel">Total:</div>
                <div class="rowPrice">${{ currencyFormat(cartTotal) }}</div>
              </div><!--END totalRow-->
            </div><!--END priceRows-->
          </div><!--END cartWrapper-->
        </div><!--END promoDetails-->
      </div><!--END copyWrapper-->

      <div class="ctaRow" v-if="promoValid && !addingPromo && !promoAdded">
        <a class="orderBtn" @click.prevent="buildCartPromo()">Add Items to Cart and Apply Discount</a>
      </div>
      <div class="addingPromoWrapper" v-if="addingPromo">
        <img class="loadingSpinner" src="@/assets/images/spinner.svg" alt="Loading Spinner" />
        Adding Promo items to your cart
      </div>
    </div>
    <!--END contentWrapper-->
  </div>
  <!--END promo wrapper-->
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CartItem from "@/components/CartItem";
// @ is an alias to /src

export default {
  name: "promo",
  data() {
    return {
      promoDetails: null,
      addingPromo: false,
      promoAdded: false,
      promoValid: false,
      userMessage: "Retrieving Promo...",
    };
  },
  computed: {
    ...mapState({
      currentCart: (state) => state.cart,
    }),
    cartSubtotal: function () {
      var runningSubtotal = 0;
      this.promoDetails.promoItems.forEach((item) => {
        runningSubtotal += item.unitPrice * item.quantity;
      });
      return runningSubtotal;
    },
    cartTotal: function () {
      return this.cartSubtotal - this.promoDetails.promoDiscount;
    },
  },
  components: {
    CartItem,
  },
  methods: {
    currencyFormat(num) {
      if (!num) {
        num = 0;
      }
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    buildCartPromo() {
      this.addingPromo = true;
      this.$store.dispatch("addPromoToCartAction", this.promoDetails.promoName)
      .then(() => {
        this.addingPromo = false;
        this.promoAdded = true;
        this.$router.push('/cart');
      });
    }
  },
  created() {
    //api call to get promo data
    axios
      .get("cart/"+
        "gaming/"+
        "bundle/"+
        this.$route.params.id)
      .then((response) => {
        if (response != undefined) {
          this.promoDetails = response.data;

          //check if promo has a display name
          if(this.promoDetails.promoDisplayName == "" && this.promoDetails.promoName){
            this.promoDetails.promoDisplayName = this.promoDetails.promoName;
          }

          //check if promo is expired
          if(this.promoDetails.status == 'expired'){
              this.userMessage = "Promo is expired";
          
          //check if promo is already applied
          }else if(this.promoDetails.couponHash == this.currentCart.current_cart.coupon_hash){
            this.userMessage = "Promo is already applied"
          }else{
            this.userMessage = "";
            this.promoValid = true;
          }
        } else {
          this.userMessage = "Promo could not be found";
        }
      })
      .catch((error) => {
        this.userMessage = "Promo could not be found";
      });
  },
};
</script>
<style lang="scss">
.promo{
  h1 {
    text-align: center;
  }

  .promoName {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin: 10px 0 5px;
  }
  .promoPre,
  .promoPost {
    text-align: center;
  }
  .promoDetails {
    margin-top: 40px;
  }
  .copyWrapper {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 50px;
  }
  .cartWrapper {
    margin-top: 6px;
    padding: 20px;

  }
  .discountRow,
  .subtotalRow,
  .shippingRow,
  .taxesRow,
  .totalRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .spacer {
      width: 100%;
    }

    .rowLabel {
      white-space: nowrap;
      text-align: right;
      box-sizing: border-box;
    }
    .rowPrice {
      white-space: nowrap;
      padding-left: 20px;
      margin-right: 20px;
      min-width: 110px;
      text-align: right;
      box-sizing: border-box;
    }
  }

  .totalRow{
    margin-top: 10px;

  }
  .discountRow {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    .rowLabel,
    .rowPrice {
      padding-top: 10px;
      border-top: 1px solid #ffffff;
      padding-bottom: 10px;
      border-bottom: 1px solid #ffffff;
    }
  }
  .priceRows {
    margin-top: 13px;
  }
  .ctaRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orderBtn {
    display:inline-block;
    margin: 30px 0;
    background-color: rgba(0, 0, 0, 0.1);
    padding:10px 52px;
    border:1px solid var(--color-text-default);
    border-radius:40px;
    transition:all 0.2s ease-out;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }

    &:hover {
      background-color: #000000;
      cursor:pointer;
    }
  }
  .addingPromoWrapper {
    display:flex;
    justify-content:center;

    img {
      margin-right:6px;
    }
  }
  .userMessage{
    text-align:center;
    margin-top:20px;
  }
}
</style>